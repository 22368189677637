import {
  Button,
  Container,
  Dialog,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Seo from "../assets/components/seo";
import Navbar from "../assets/components/Navbar";
import Heading from "../assets/components/Heading";
import { Brightness1 } from "@mui/icons-material";
import Footer from "../assets/components/Footer";

import { useWindowSize } from "react-use";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";

const Index = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { language } = useI18next();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "0 !important",
        margin: "0 !important",
        backgroundColor: "#fff",
      }}
    >
      <Seo />

      <Navbar />
      <Grid container justifyContent="center" spacing={12} marginBottom={10}>
        {/* Banner principal Desktop */}
        <Grid
          item
          bgcolor="#d9d8d4"
          xs={12}
          textAlign="center"
          display={{ xs: "none", sm: "flex" }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/festival-del-chocolate.appspot.com/o/banner-principal.png?alt=media&token=946c091b-775a-446c-b34a-f5d865c5e8c8"
            width="100%"
            height="100%"
            style={{ maxWidth: 1920, objectFit: "cover" }}
            alt="Banner principal"
          />
        </Grid>
        {/* Fin banner principal Desktop */}

        {/* Banner principal Mobile */}
        <Grid
          item
          container
          bgcolor="#d9d8d4"
          xs={12}
          display={{ xs: "flex", sm: "none" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item container xs={12} justifyContent="center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/festival-del-chocolate.appspot.com/o/banner-movil.jpg?alt=media&token=5a932d14-4f61-4b89-ad10-6379fb894682"
              width="100%"
              height="100%"
              style={{ objectFit: "cover" }}
              alt="Banner principal"
            />
          </Grid>
        </Grid>
        {/* Fin banner principal Mobile */}
        <Grid item container xs={12} justifyContent="center">
          <Button
            href="https://tupase.tabasco.gob.mx/servicios-externos/#/solicitud"
            target="_blank"
            variant="contained"
            size="large"
          >
            {language === "es"
              ? "Pago del estacionamiento con CoDi"
              : language === "en"
              ? "Paying for parking with CoDi"
              : null}
          </Button>
        </Grid>
        {/* Inicio chefs*/}
        {/*    <Grid item container maxWidth={1520}>
          <Splide
            aria-label="Chefs invitados"
            options={{
              type: "loop",
              drag: "free",
              focus: "center",
              perPage: 4,
              autoScroll: {
                speed: 1,
              },
              breakpoints: {
                1200: { perPage: 4 },
                900: { perPage: 2.5 },
                600: { perPage: 1.5 },
              },
            }}
            extensions={{ AutoScroll }}
          >
            {CHEFS.map((item) => (
              <SplideSlide>
                <img
                  src={item}
                  alt={item}
                  style={{ width: "100%", maxWidth: 340 }}
                />
              </SplideSlide>
            ))}
          </Splide>
        </Grid> */}
        {/* Fin chefs */}

        {/* Seccion de origen */}
        <Grid
          item
          container
          maxWidth={1520}
          marginX={{ xs: 2, lg: 3 }}
          spacing={{ lg: 4 }}
          alignItems="center"
          rowSpacing={4}
        >
          <Grid item xs={12}>
            <Heading label={t("headingOrigen")} />
          </Grid>
          <Grid item lg={6}>
            <Typography style={{ whiteSpace: "pre-wrap" }}>
              {t("descriptionOrigen")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            lg={6}
            sx={{
              position: "relative",
              height: {
                xs: 300,
                sm: 500,
                lg: 400,
              },
            }}
          >
            <video loop muted={true} className="video" playsInline controls>
              <source
                src="https://firebasestorage.googleapis.com/v0/b/festival-del-chocolate.appspot.com/o/PROMO3%20FDCH%20FINAL%20(1).mp4?alt=media&token=4f7651aa-9a7c-4b01-85ae-8b5786ca858f"
                type="video/mp4"
              />
            </video>
          </Grid>
        </Grid>
        {/* Fin seccion de origen */}

        {/* Inicio patrocinadores grandes*/}
        {/*   <Grid item container maxWidth={1520}>
          <Grid item xs={12}>
            <Heading label={t("patrocinadoresTitle")} />
          </Grid>
          <Grid item>
            <Splide
              aria-label="patrocinadores invitados"
              options={{
                drag: "free",
                focus: "center",
                perPage: 4,
                autoScroll: {
                  speed: 0.8,
                },
                type: "loop",
                breakpoints: {
                  1200: { perPage: 4 },
                  900: { perPage: 2.5 },
                  600: { perPage: 1.5 },
                },
              }}
              extensions={{ AutoScroll }}
            >
              {PATROCINADORES.map((item) => (
                <SplideSlide>
                  <img
                    src={item}
                    alt={item}
                    style={{
                      width: "100%",
                      maxWidth: 300,
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </SplideSlide>
              ))}
            </Splide>
          </Grid>
        </Grid> */}
        {/* Fin patrocinadores grandes */}

        {/* Seccion de certificacion */}
        {/*    <Grid
          item
          container
          maxWidth={1520}
          marginX={{ xs: 2, lg: 3 }}
          spacing={{ lg: 4 }}
          alignItems="center"
          rowSpacing={4}
        >
          <Grid item xs={12}>
            <Heading
              label="INTERNATIONAL INSTITUTE OF 
CHOCOLATE AND CACAO TESTING"
            />
          </Grid>
          <Grid item lg={6}>
            <Typography style={{ whiteSpace: "pre-wrap" }}>
              {t("descriptionCertification")}
            </Typography>
          </Grid>
          <Grid item direction="column" container lg={6} spacing={2}>
            <Grid item>
              <img
                src={certificacionUrl}
                width="100%"
                height="100%"
                style={{
                  maxWidth: 720,
                  objectFit: "contain",
                  margin: "0 auto",
                }}
                alt="Banner certificacion"
              />
            </Grid>
            <Grid item container xs={12} md={6} spacing={1} marginBottom={4}>
              <Grid item>
                <Button variant="outlined" onClick={() => setOpen(true)}>
                  Ver convocatoria
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => navigate("/certificacion")}
                >
                  Registrarse
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        {/* Fin seccion de certificacion */}

        {/* Seccion de país invitado */}
        <Grid
          item
          container
          maxWidth={1520}
          marginX={{ xs: 2, lg: 3 }}
          spacing={{ lg: 4 }}
          alignItems="center"
          rowSpacing={4}
        >
          <Grid item xs={12}>
            <Heading label={t("headingCountry")} />
          </Grid>

          <Grid item lg={6}>
            <Typography style={{ whiteSpace: "pre-wrap" }}>
              {t("descriptionCountry")}
            </Typography>
          </Grid>
          <Grid item container lg={6}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/festival-del-chocolate.appspot.com/o/image001-1.png?alt=media&token=e8bc6053-040f-419c-a25a-04233fabede2"
              width="100%"
              height="100%"
              style={{ maxWidth: 500, objectFit: "contain", margin: "0 auto" }}
              alt="Banner principal"
            />
          </Grid>
          {/*  <Grid item container xs={12}>
            <Splide
              aria-label="Chefs invitados"
              options={{
                type: "loop",
                drag: "free",
                focus: "center",
                perPage: 4,
                autoScroll: {
                  speed: 1,
                },
                breakpoints: {
                  1200: { perPage: 4 },
                  900: { perPage: 2.5 },
                  600: { perPage: 1.5 },
                },
              }}
              extensions={{ AutoScroll }}
            >
              {INVITADOSITALIA.map((invitado) => (
                <SplideSlide>
                  <img
                    src={invitado}
                    alt={invitado}
                    style={{ width: "100%", maxWidth: 300 }}
                  />
                </SplideSlide>
              ))}
            </Splide>
          </Grid> */}
        </Grid>
        {/* Fin seccion de país invitado */}

        {/* Seccion de showcase */}
        {/*   <Grid
          item
          container
          maxWidth={1520}
          marginX={{ xs: 2, lg: 3 }}
          spacing={{ lg: 4 }}
          alignItems="center"
          rowSpacing={4}
        >
          <Grid item xs={12}>
            <Heading label="SHOWCASE" />
          </Grid>

          <Grid item container lg={4}>
            <img
              src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/05/15/20230515142231-ae17c8f1-me.jpg"
              width="100%"
              height="100%"
              style={{ maxWidth: 720, objectFit: "contain", margin: "0 auto" }}
              alt="Banner principal"
            />
          </Grid>
          <Grid item lg={4}>
            <Typography style={{ whiteSpace: "pre-wrap" }}>
              {t("descriptionShowcase")}
            </Typography>
          </Grid>
          <Grid item container lg={4}>
            <img
              src="https://imagenturistica.tabasco.gob.mx/i.php?/upload/2023/05/31/20230531152228-4b887c47-xx.jpg"
              width="100%"
              height="100%"
              style={{ maxWidth: 720, objectFit: "contain", margin: "0 auto" }}
              alt="Banner principal"
            />
          </Grid>
        </Grid> */}
        {/* Fin seccion de showcase */}

        {/* Fin seccion de showcase */}

        <Grid item container maxWidth={1520}>
          <Grid
            item
            container
            bgcolor="#6c3f37"
            paddingY={6}
            justifyContent="center"
            rowSpacing={2}
          >
            <Grid item xs={12}>
              <Heading label={t("headingBanco")} isWhite />
            </Grid>
            <Grid item lg={6} textAlign="center">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/festival-del-chocolate.appspot.com/o/ORIGEN.png?alt=media&token=97cd54fd-3efa-4c54-97b4-7126fab003c3"
                width="100%"
                height="100%"
                style={{
                  maxWidth: 550,
                  objectFit: "contain",
                }}
                alt="Banco"
              />
            </Grid>
            <Grid
              item
              container
              lg={6}
              paddingRight={{ lg: 12 }}
              rowSpacing={2}
            >
              <Grid item px={2}>
                <Typography style={{ whiteSpace: "pre-wrap", color: "white" }}>
                  {t("descriptionBanco")}
                </Typography>
              </Grid>
              {/*   <Grid item xs={12}>
                <Typography textAlign="center" color="#FDF6E8" variant="h5">
                  RESULTADOS 2022
                </Typography>
              </Grid>
              <Grid item container justifyContent="center">
                {t("resultados", { returnObjects: true }).map((resultado) => (
                  <Grid item>
                    <img
                      src={resultado}
                      width="100%"
                      height="100%"
                      style={{
                        maxWidth: 150,
                        objectFit: "contain",
                        padding: 5,
                      }}
                      alt="Banco"
                    />
                  </Grid>
                ))}
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        {/* Seccion de dimensiones */}

        {/* Seccion de dimensiones */}
        <Grid
          item
          container
          maxWidth={1520}
          marginX={{ xs: 2, lg: 3 }}
          spacing={{ lg: 2 }}
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Heading label={t("headingDimensiones")} />
          </Grid>
          {t("dimensiones", { returnObjects: true }).map((dimension, i) => (
            <Grid item container xs={12} lg key={i} rowSpacing={1}>
              <Grid item xs={12} textAlign="center">
                <img
                  src={dimension.image}
                  width="100%"
                  height="100%"
                  style={{
                    maxWidth: width <= 600 ? 70 : 130,
                    objectFit: "contain",
                  }}
                  alt="Dimension"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  color="#6c3f37"
                  fontWeight="bold"
                >
                  {dimension.name}
                </Typography>
              </Grid>
              <Grid item>
                <List>
                  {dimension.content.map((item, i) => (
                    <ListItem>
                      <ListItemIcon>
                        <Brightness1 fontSize="inherit" htmlColor="#6c3f37" />
                      </ListItemIcon>
                      <ListItemText primary={item} key={i} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {/* Fin seccion de dimensiones */}

        {/*Patrocinadores orsan*/}
        {/*  <Grid
          item
          display={{ xs: "flex", sm: "none" }}
          component="a"
          href="https://www.orsan.com.mx"
          target="_blank"
        >
          <img
            src="https://imagenturistica.tabasco.gob.mx/upload/2023/09/26/20230926133725-5714e6ce.png"
            alt="Orsan patrocinio"
            style={{ width: "100%", maxWidth: 300 }}
          />
        </Grid>

        <Grid
          item
          display={{ xs: "none", sm: "flex" }}
          component="a"
          href="https://www.orsan.com.mx"
          target="_blank"
        >
          <img
            src="https://imagenturistica.tabasco.gob.mx/upload/2023/09/26/20230926133726-b90dd145.png"
            alt="Orsan patrocinio"
            style={{ width: "100%", maxWidth: 728 }}
          />
        </Grid> */}
        {/*Fin orsan*/}

        {/* Seccion de actividades */}
        <Grid
          item
          container
          maxWidth={1520}
          marginX={{ xs: 2, lg: 3 }}
          spacing={{ lg: 4 }}
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Heading label={t("headingFestival")} />
          </Grid>
          {t("actividades", { returnObjects: true }).map((actividad) => (
            <Grid item container xs={6} lg={3} rowSpacing={1}>
              <Grid item xs={12} textAlign="center">
                <img
                  src={actividad.image}
                  width="100%"
                  height="100%"
                  style={{
                    maxWidth: 70,
                    objectFit: "contain",
                  }}
                  alt={actividad.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  color="#6c3f37"
                  fontWeight="bold"
                >
                  {actividad.name}
                </Typography>
              </Grid>
              <Grid item width="90%">
                <Typography>{actividad.content}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {/* Fin seccion de actividades */}

        {/* Seccion de concursos */}
        <Grid
          item
          container
          maxWidth={1520}
          marginX={{ xs: 2, lg: 3 }}
          spacing={{ lg: 4 }}
          alignItems="center"
          rowSpacing={4}
        >
          <Grid item xs={12}>
            <Heading label={t("headingConcursos")} />
          </Grid>

          <Grid item lg={6}>
            <img
              src="https://imagenturistica.tabasco.gob.mx/upload/2023/05/25/20230525111921-8b92e09b.jpg"
              alt="Concurso de dibujo infantil"
            />
          </Grid>
          <Grid item container lg={6}>
            <img
              src="https://imagenturistica.tabasco.gob.mx/upload/2023/05/25/20230525111922-c32b1aa3.jpg"
              alt="Concurso de fotografia"
            />
          </Grid>
        </Grid>
        {/* Fin seccion de concursos */}

        {/* Inicio patrocinadores*/}
        {/*    <Grid item container maxWidth={1520}>
          <Grid item xs={12}>
            <Heading label={t("patrocinadoresChicosTitle")} />
          </Grid>
          <Grid item>
            <Splide
              aria-label="patrocinadores invitados"
              options={{
                drag: "free",
                focus: "center",
                perPage: 8,
                autoScroll: {
                  speed: 0.8,
                },
                type: "loop",
                breakpoints: {
                  1200: { perPage: 8 },
                  900: { perPage: 5 },
                  600: { perPage: 4 },
                },
              }}
              extensions={{ AutoScroll }}
            >
              {PATROCINADORES_CH.map((item) => (
                <SplideSlide>
                  <img
                    src={item}
                    alt={item}
                    style={{
                      width: "100%",
                      maxWidth: 280,
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </SplideSlide>
              ))}
            </Splide>
          </Grid>
        </Grid> */}
        {/* Fin patrocinadores */}

        {/* Inicio colaboradores*/}
        {/*  <Grid item container maxWidth={1520}>
          <Grid item xs={12}>
            <Heading label={t("patrocinadoresTitle")} />
          </Grid>
          <Grid item>
            <Splide
              aria-label="patrocinadores invitados"
              options={{
                drag: "free",
                focus: "center",
                perPage: 5,
                autoScroll: {
                  speed: 0.8,
                },
                rewind: true,
              }}
              extensions={{ AutoScroll }}
            >
              {PATROCINADORES.map((item) => (
                <SplideSlide>
                  <img
                    src={item}
                    alt={item}
                    style={{
                      width: "100%",
                      maxWidth: 280,
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </SplideSlide>
              ))}
            </Splide>
          </Grid>
        </Grid> */}
        {/* Fin colaboradores */}
      </Grid>

      <Footer />

      <Fab
        variant="extended"
        href="/programa"
        sx={{
          margin: 0,
          top: "auto",
          left: { xs: 20, sm: "auto" },
          bottom: 20,
          right: 20,
          position: "fixed",
          bgcolor: "#a57a3e",
          color: "white",
          "&:hover": {
            backgroundColor: "#a57a3e",
          },
        }}
      >
        {t("origen")}
      </Fab>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/festival-del-chocolate.appspot.com/o/promo.jpeg?alt=media&token=47ca2920-3dbb-44a9-a7f1-8eccaa831eb9"
          style={{
            width: "100%",
            maxWidth: 500,
            objectFit: "contain",
          }}
        />
      </Dialog>
    </Container>
  );
};

export default Index;

export const QUERY_TRANSLATION = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["home"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
